import truncate from 'lodash/truncate';
import React from 'react';
import 'twin.macro';
const CardDescription = ({ text, ...props }) => {
  return (
    <div tw="p-3" {...props}>
      <p tw="text-sm leading-6 text-gray-500 ">
        {truncate(text, { length: 100 })}
      </p>
    </div>
  );
};

export default CardDescription;
