import SVG from 'react-inlinesvg';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import 'twin.macro';
const NewsArticleChip = ({ title, color, icon }) => {
  return (
    <Chip
      label={title}
      size="small"
      tw="text-white px-1"
      css={{
        backgroundColor: color
      }}
      icon={
        <SVG
          src={icon.url}
          tw=" flex-shrink-0 text-white fill-current w-4 h-4"
        />
      }
    />
  );
};

export default NewsArticleChip;
