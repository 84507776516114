import React from 'react';
import 'twin.macro';
const CardTitle = ({ text }) => {
  return (
    <h3 tw="mt-2 text-base leading-7 font-semibold text-gray-600 truncate max-w-full">
      {text}
    </h3>
  );
};

export default CardTitle;
