import Moment from 'react-moment';
import React, { FC } from 'react';
import 'twin.macro';
import MailLink from '@leuven2030/framework/Mail/MailLink';
type Props = {
  date: string;
};
const EventDate: FC<Props> = ({ date, ...rest }) => {
  return (
    <div
      tw="p-3 bg-gray-100 flex flex-col items-center whitespace-nowrap"
      {...rest}
    >
      <Moment
        tw="text-primary-500 tracking-wide uppercase text-3xl font-bold "
        format="DD"
      >
        {date}
      </Moment>
      <Moment tw="uppercase text-sm font-bold text-gray-600" format="MMMM">
        {date}
      </Moment>
      <Moment
        tw="block uppercase text-sm font-bold text-gray-600"
        format="HH:mm"
      >
        {date}
      </Moment>
    </div>
  );
};

export default EventDate;
