import Moment from 'react-moment';
import React, { FC, useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Card from '@leuven2030/framework/Card/Card';
import CardImage from '@leuven2030/framework/Card/CardImage';
import get from 'lodash/get';
import tw from 'twin.macro';
import EventDate from '@leuven2030/framework/Event/EventDate';
import PrismicMeta from '@leuven2030/framework/Prismic/PrismicMeta';
import NewsArticleChip from '@leuven2030/framework/News/NewsArticleChip';
import EventType from '@leuven2030/framework/Event/EventType';
import CardDescription from '@leuven2030/framework/Card/CardDescription';
import CardTitle from '@leuven2030/framework/Card/CardTitle';

export type NewsCardOptions = {
  size?: 'small' | 'default';
};
const NewsCard: FC<
  NewsCardOptions & {
    title: string;
    description: string;
    group: any;
    published_date: string;
    cover: any;
    event_place: string;
    event_link: string;
    event_address: string;
    event_date_start: string;
    _meta: PrismicMeta;
  }
> = ({
  title,
  size,
  group,
  description,
  published_date,
  cover,
  event_place,
  event_link,
  event_address,
  event_date_start,
  _meta
}) => {
  const {
    prismic: {
      news_page: { read_more_label }
    }
  } = useContext(PageContext);
  const isSmall = size == 'small';
  return (
    <Card prismicMeta={_meta} tw="justify-between">
      <div>
        <div tw="p-3">
          <div
            tw="space-y-2"
            css={isSmall && tw`flex-col items-start space-x-0 space-y-1 pb-1`}
          >
            <CardTitle text={title} />
            <Moment
              tw="text-gray-400 tracking-wide uppercase text-xs"
              parse="YYYY-MM-DD"
              format="dddd, D MMMM YYYY"
            >
              {published_date}
            </Moment>
          </div>
        </div>
        {cover && (
          <CardImage src={cover.url}>
            {get(group, '_meta.uid') == 'event' && (
              <div tw="flex flex-col items-center space-y-2">
                <EventDate date={event_date_start} />
                <EventType
                  online={{ link: event_link }}
                  location={{ address: event_address, place: event_place }}
                />
              </div>
            )}
          </CardImage>
        )}
        <CardDescription text={description} />
      </div>
      <div
        tw="p-3  space-y-2 "
        css={isSmall && tw`flex-col items-start space-x-0 space-y-1 `}
      >
        <div tw="flex flex-row items-center justify-between  space-x-2">
          {group && <NewsArticleChip {...group} />}
          <span tw="block text-base leading-6 text-sm text-primary-600 transition ease-in-out duration-150">
            {read_more_label}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default NewsCard;
