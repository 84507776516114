import Chip from '@material-ui/core/Chip';
import React, { FC, useContext } from 'react';
import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';

type Props = {
  location?: {
    place?: string;
    address: string;
  };
  online?: {
    link: string;
  };
};
const EventType: FC<Props> = ({ online, location, ...rest }) => {
  const isOnline = !!online.link;

  const {
    prismic: {
      news_page: { online_meeting_label }
    }
  } = useContext(PageContext);

  if (isOnline) {
    return (
      <Chip
        label={online_meeting_label}
        size="small"
        tw=" bg-white dark:(text-white bg-primary-500)"
        {...rest}
      />
    );
  }
  return (
    <div tw="flex-1" {...rest}>
      <p>{location.place}</p>
      <p>{location.address}</p>
    </div>
  );
};

export default EventType;
