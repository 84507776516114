import NewsCard, { NewsCardOptions } from '@leuven2030/framework/News/NewsCard';
import 'twin.macro';
import { FC } from 'react';

const NewsCardGrid: FC<{
  edges: any[];
  cardOptions: NewsCardOptions;
}> = ({ edges, cardOptions, ...props }) => {
  return (
    <div tw="grid gap-3 md:grid-cols-2 xl:grid-cols-3" {...props}>
      {edges.map(({ node }) => (
        <NewsCard key={node._meta.uid} {...cardOptions} {...node} />
      ))}
    </div>
  );
};

export default NewsCardGrid;
