import tw from 'twin.macro';
import React, { FC, useContext } from 'react';
import { CardContext } from '@leuven2030/framework/Card/Card';
import ImageURL from '@leuven2030/framework/Image/ImageURL';
import ImageBackground from '@leuven2030/framework/Image/ImageBackground';
type Props = {
  src: string;
  imgixParams?: any;
};
const CardImage: FC<Props> = ({ imgixParams, children, src }) => {
  const { isHovering } = useContext(CardContext);
  const imageHeight = 160;
  return (
    <div
      tw="overflow-x-hidden overflow-y-hidden w-full relative"
      css={{ height: imageHeight, maxHeight: imageHeight }}
    >
      <ImageBackground
        tw="absolute w-full h-full transform transition-transform duration-300 ease-in-out bg-center bg-cover"
        src={src}
        imgixParams={{
          w: 400,
          h: 200,
          fit: 'crop',
          ...imgixParams
        }}
        css={[isHovering && tw`scale-125`]}
      />
      {!!children && (
        <div tw="flex flex-col items-center justify-center absolute w-full h-full top-0 bottom-0">
          {children}
        </div>
      )}
    </div>
  );
};

export default CardImage;
